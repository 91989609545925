<template>
  <div class="container">

    <div class="graduate-work-temp mt-4 mb-4">
      <h4 class="text-center mb-4">Комплексный экзамен</h4>

      <AddGraduateWorkExam/>

      <div class="row mb-4">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addGraduateWorkThemeTempModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить комплексный экзамен
          </button>
        </div>
      </div>

      <DataTable :value="graduateWorkTemp.graduateWorkThemes" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <!--        <Column field="theme_name_kz" header="Название темы на казахском"></Column>-->
        <!--        <Column field="theme_name_ru" header="Название темы на русском"></Column>-->
        <!--        <Column field="theme_name_en" header="Название темы на английском"></Column>-->
        <Column header="Название темы">
          <template #body="{data}">
            <div v-if="data.students[0].student_study_language_id == 82">
              {{ data.theme_name_kz }}
            </div>
            <div v-else-if="data.students[0].student_study_language_id == 137">
              {{ data.theme_name_ru }}
            </div>
            <div v-else>
              {{ data.theme_name_en }}
            </div>
          </template>
        </Column>
        <Column field="education_program_code" header="Код ОП"></Column>
        <Column header="Отделение">
          <template #body="{data}">
            <p v-if="data.language_id == 137">Русское</p>
            <p v-if="data.language_id == 82">Казахское</p>
            <p v-if="data.language_id == 39">Английское</p>
          </template>
        </Column>
        <Column field="study_level_name" header="Уровень обучения">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{ slotProps.value }}</span>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <span>{{ slotProps.option }}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="academic_year_name" header="Учебный год"></Column>
        <Column header="Автор темы">
          <template #body="{data}">
            {{ data.lastname }} {{ data.firstname }}
          </template>
        </Column>
        <Column header="Тип">
          <template #body="{data}">
            <div v-if="data.is_project == 1">
              Проект
            </div>
            <div v-else>
              <div v-if="data.is_comlex_exam == 1">
                Комплексный экзамен
              </div>
              <div v-else>
                Выпускная работа
              </div>
            </div>
          </template>
        </Column>
        <Column header="Студенты">
          <template #body="{data}">

            <div v-if="data.students[0].student_id != null">
              <div v-for="(student, studentIndex) in data.students" :key="'student'+studentIndex" class="mb-2">
                {{ student.student_lastname }} {{ student.student_firstname }}
              </div>
            </div>
            <div v-else>
              <span>Студенты не выбраны</span>
            </div>

          </template>
        </Column>
        <Column header="Действия">

          <template #body="{data}">

            <div class="mb-2" v-if="data.is_project == 1">

              <Button icon="pi pi-user-plus" @click="openChooseStudentsProjectDialog(data.id)"/>

            </div>
            <div class="mb-2" v-else>
              <Button icon="pi pi-user-plus" @click="openChooseStudentWithCourse(data.id, data.education_program_id)"/>
            </div>
            <Button class="p-button-danger" icon="pi pi-trash" @click="confirmDeleteGraduateWorkTheme(data.id)"/>


          </template>


        </Column>


      </DataTable>


      <Dialog v-model:visible="chooseStudentDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Выбор студента</h5>
        </template>

        <div class="form-check" v-for="(item, index) in graduateWorkTemp.students" :key="index">
          <input class="form-check-input" type="checkbox" :value="item" :id="index"
                 :checked="graduateWorkTemp.graduateWorkThemes.find(i => i.id === graduateWorkTemp.selectedGraduateWorkThemeId).students.some(s => s.student_id === item.id)"
                 @change="selectStudent($event, item)">
          <label class="form-check-label" for="flexCheckDefault">
            {{ item.fullname }}
          </label>
        </div>

        <!--        <MultiSelect v-model="selectedStudents" :options="[...graduateWorkTemp.students]" optionLabel="fullname" placeholder="Выберите студентов"/>-->

        <!--        <div style="height: 200px" class="col-md-12 mt-4">-->
        <!--          <div class="form-group row mt-4">-->
        <!--            <label for="student_id" class="col-md-3 col-form-label">Студент</label>-->
        <!--            <div class="col-md-9" id="student_id">-->
        <!--              <select class="form-control form-select"-->
        <!--                      @input="changeStudentId($event)">-->
        <!--                <option v-for="(item, index) in [{id: 0, last_name: 'Выберите студента'}, ...graduateWorkTemp.students]"-->
        <!--                        :value="item.id"-->
        <!--                        :key="index">{{ item.last_name }} {{ item.first_name }}-->
        <!--                </option>-->
        <!--              </select>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeChooseStudentDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitSelectionTheme"/>
        </template>
      </Dialog>


      <!--      <Dialog v-model:visible="editChooseStudentDisplay" :style="{width: '50vw'}">-->
      <!--        <template #header>-->
      <!--          <h5>Редактировать студента</h5>-->
      <!--        </template>-->

      <!--        <div style="height: 200px" class="col-md-12 mt-4">-->
      <!--          <div class="form-group row mt-4">-->
      <!--            <label class="col-md-3 col-form-label">Студент</label>-->
      <!--            <div class="col-md-9">-->
      <!--              <select class="form-control form-select"-->
      <!--                      @input="changeEditChooseStudentId($event)">-->
      <!--                <option v-for="(item, index) in [{id: 0, last_name: 'Выберите студента'}, ...graduateWorkTemp.students]"-->
      <!--                        :value="item.id"-->
      <!--                        :selected="item.id == editStudentId"-->
      <!--                        :key="index">{{ item.last_name }} {{ item.first_name }}-->
      <!--                </option>-->
      <!--              </select>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--        </div>-->

      <!--        <template #footer>-->
      <!--          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeEditChooseStudentDialog"/>-->
      <!--          <Button label="Обновить" icon="pi pi-check" @click="putSelectionTheme"/>-->
      <!--        </template>-->
      <!--      </Dialog>-->


      <Dialog v-model:visible="chooseStudentsProjectDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Выбор студентов (проект)</h5>
        </template>

        <div style="height: 200px" class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="project_student_id" class="col-md-3 col-form-label">Студент</label>
            <div class="col-md-9" id="project_student_id">
              <MultiSelect v-model="selectedProjectStudents" :options="graduateWorkTemp.students" optionLabel="fullname"
                           :selectionLimit="3" placeholder="Выберите студентов"/>
              <!--              {{selectedProjectStudents}}-->
              <!--              <select class="form-control form-select"-->
              <!--                      @input="changeStudentId($event)">-->
              <!--                <option v-for="(item, index) in [{id: 0, last_name: 'Выберите студента'}, ...graduateWorkTemp.students]"-->
              <!--                        :value="item.id"-->
              <!--                        :key="index">{{ item.last_name }} {{ item.first_name }}-->
              <!--                </option>-->
              <!--              </select>-->
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeChooseStudentsProjectDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitSelectionTheme"/>
        </template>
      </Dialog>


      <!--      <Toast />-->
      <ConfirmDialog></ConfirmDialog>
    </div>
  </div>

  <!--  </div>-->
</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import AddGraduateWorkExam from '@/views/graduate-work/AddGraduateWorkExam.vue'

export default {
  name: "GraduateWorkTemp",
  components: {
    AddGraduateWorkExam
  },
  data() {
    return {
      selectedStudents: [],
      chooseStudentDisplay: false,
      chooseStudentsProjectDisplay: false,
      selectedProjectStudents: null,
      selectAll: false,
      editChooseStudentDisplay: false,
      editStudentId: null,
      filters: {
        'study_level_name': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
    }
  },
  computed: {
    ...mapState('graduateWorkTemp', ['graduateWorkTemp']),
    // getStudentFullName() {
    //   let last_name = this.graduateWorkTemp.students.last_name
    //   let first_name = this.graduateWorkTemp.students.first_name
    //   return  last_name + first_name
    // },
  },

  methods: {
    ...mapActions('graduateWorkTemp', ['GET_STUDY_LEVELS', 'GET_GRADUATE_WORK_THEMES', 'POST_SELECTION_THEME_EXAM',
      'GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE', 'PUT_SELECTION_THEME', 'DELETE_SELECTION_THEME', 'DELETE_GRADUATE_WORK_THEME', 'GET_STUDENTS_WITH_COURSE_ID']),
    ...mapMutations('graduateWorkTemp', ['SET_GRADUATE_WORK_THEME_ID', 'SET_SELECTION_THEME_STUDENT_ID',
      'SET_SELECTION_THEME_STUDENTS_PROJECT', 'SET_EDIT_SELECTION_THEME_STUDENT_ID', 'SET_SELECTION_THEME_ID']),
    confirmDeleteSelectionTheme(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите удалить студента?',
        header: 'Удаление студента',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          await this.DELETE_SELECTION_THEME(id)
          await this.GET_GRADUATE_WORK_THEMES(1)
        }
      });
    },
    selectStudent(e, obj) {
      console.log(e.target.checked)
      if (e.target.checked) {
        this.selectedStudents.push(obj)
      } else {
        let index = this.selectedStudents.findIndex(i => i.id == obj.id)
        this.selectedStudents.splice(index, 1);
      }
    },
    confirmDeleteGraduateWorkTheme(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите удалить темы?',
        header: 'Удаление темы',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          await this.DELETE_GRADUATE_WORK_THEME(id)
          await this.GET_GRADUATE_WORK_THEMES(1)
        }
      });
    },
    async putSelectionTheme() {
      this.editChooseStudentDisplay = false
      await this.PUT_SELECTION_THEME()
      await this.GET_GRADUATE_WORK_THEMES()
    },
    async openEditChooseStudents(selectedGraduateWorkThemeId, selection_theme_id, student_id) {
      console.log(selection_theme_id, 'edit selection theme id')
      console.log(student_id, 'edit student_id')
      this.editStudentId = student_id
      this.editChooseStudentDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.SET_SELECTION_THEME_ID(selection_theme_id)
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE()
      //   { "id": "3665", "fullname": "Александра Атанова", "last_name": "Атанова", "first_name": "Александра", "middle_name": "Ивановна" } ]
    },
    closeEditChooseStudentDialog() {
      this.editChooseStudentDisplay = false
    },
    async openChooseStudentDialog(selectedGraduateWorkThemeId) {
      console.log(selectedGraduateWorkThemeId, 'selectedGraduateWorkThemeId')
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
      this.chooseStudentDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE()
    },
    async openChooseStudentWithCourse(selectedGraduateWorkThemeId, educationProgramId) {
      this.selectedStudents = [];
      console.log(selectedGraduateWorkThemeId, 'selectedGraduateWorkThemeId')
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
      this.chooseStudentDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.GET_STUDENTS_WITH_COURSE_ID(educationProgramId)

      this.selectedStudents.push(...this.graduateWorkTemp.graduateWorkThemes.find(i => i.id === this.graduateWorkTemp.selectedGraduateWorkThemeId).students.filter(i=>i.selection_theme_id).map(i => ({
        id: i.student_id,
        fullname: i.student_firstname + i.student_lastname
      })))

      console.log(this.selectedStudents)
    },
    async openChooseStudentsProjectDialog(selectedGraduateWorkThemeId) {
      console.log(selectedGraduateWorkThemeId, 'selectedGraduateWorkThemeId')
      this.chooseStudentsProjectDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE()

    },
    closeChooseStudentDialog() {
      this.chooseStudentDisplay = false
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
    },
    closeChooseStudentsProjectDialog() {
      this.chooseStudentsProjectDisplay = false
      this.selectedProjectStudents = null
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0

    },
    changeStudentId(e) {
      const value = e.target.value
      // this.selectedStudentId= value
      // console.log(this.selectedStudentId, "selectedStudentId")
      this.SET_SELECTION_THEME_STUDENT_ID(value)

    },
    changeEditChooseStudentId(e) {
      const value = e.target.value
      this.SET_EDIT_SELECTION_THEME_STUDENT_ID(value)
    },
    async submitSelectionTheme() {
      await this.SET_SELECTION_THEME_STUDENTS_PROJECT(this.selectedStudents)

      // await this.POST_SELECTION_THEME().then(res => {
      //   if (res) {
      //     this.chooseStudentDisplay = false
      //     this.GET_GRADUATE_WORK_THEMES()
      //
      //     this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
      //   } else {
      //     const errorText = res.errors[0].map(err => err.message).join('\n');
      //     this.$error({title: 'Добавление', text: errorText})
      //   }
      // })


      const res = await this.POST_SELECTION_THEME_EXAM()
      if (res) {
        this.chooseStudentDisplay = false
        this.GET_GRADUATE_WORK_THEMES(1)
        this.$message({title: 'Добавление', text: 'Данные успешно добавились'});
      } else {
        this.$error({title: 'Добавление', text: 'Произошла ошибка'})
      }


      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
    }

  },

  async mounted() {
    this.GET_GRADUATE_WORK_THEMES(1)
  }
}
</script>

<style scoped>
.remove-item__button {
  color: red;
}
</style>
